import { ConfigurationItem, DataType, Widget } from "@mob/shielder-metadata";

import { CertificateItem } from "./certificate-item";
import { CheckboxItem } from "./checkbox-item";
import { ColorItem } from "./color-item";
import { DropdownItem } from "./dropdown-item";
import { InputItem } from "./input-item";
import { NumberInputItem } from "./number-input-item";
import { RadioGroupItem } from "./radio-group-item";
import { UrlItem } from "./url-item";

interface ItemSelectorProps {
  first: boolean;
  disabled?: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  item: ConfigurationItem;
  toggleDescription: (() => void) | undefined;
  isDescriptionVisible: boolean;
  validationDependency: string[] | undefined;
}
export const ItemSelector = ({ item, validationDependency, ...rest }: ItemSelectorProps) => {
  switch (item.widget) {
    case Widget.Checkbox:
      return <CheckboxItem name={item.name} label={item.label} validationDependency={validationDependency} {...rest} />;

    case Widget.CertificateUploader:
      return (
        <CertificateItem
          name={item.name}
          allowedFileExtensions={item.allowedFileExtensions}
          isRequired={Boolean(item.validations?.required)}
          {...rest}
        />
      );

    case Widget.ColorInput:
      return <ColorItem name={item.name} label={item.label} placeholder={item.placeholder} {...rest} />;

    case Widget.Dropdown:
      switch (item.data.type) {
        // Has to use this ugly duplicates in order to satisfy TypeScript
        case DataType.Integer:
          return (
            <DropdownItem
              name={item.name}
              label={item.label}
              items={item.data.items}
              validationDependency={validationDependency}
              {...rest}
            />
          );
        case DataType.String:
          return (
            <DropdownItem
              name={item.name}
              label={item.label}
              items={item.data.items}
              validationDependency={validationDependency}
              {...rest}
            />
          );
      }

    case Widget.Input:
    case Widget.MultilineInput:
      return (
        <InputItem
          multiline={item.widget === Widget.MultilineInput}
          name={item.name}
          label={item.label}
          placeholder={item.placeholder}
          helperText={item.helperText}
          validations={item.validations}
          {...rest}
        />
      );

    case Widget.NumberInput:
      return <NumberInputItem name={item.name} label={item.label} validations={item.validations} {...rest} />;

    case Widget.RadioGroup:
      // prettier-ignore
      switch (item.data.type) {
          // Has to use this ugly duplicates in order to satisfy TypeScript
          case DataType.Boolean:
            return <RadioGroupItem name={item.name} label={item.label} items={item.data.items} dataType={item.data.type} validationDependency={validationDependency} {...rest} />;
          case DataType.Integer:
            return <RadioGroupItem name={item.name} label={item.label} items={item.data.items} dataType={item.data.type} validationDependency={validationDependency} {...rest} />;
          case DataType.String:
            return <RadioGroupItem name={item.name} label={item.label} items={item.data.items} dataType={item.data.type} validationDependency={validationDependency} {...rest} />;
        }

    case Widget.Url:
      return (
        <UrlItem
          name={item.name}
          label={item.label}
          placeholder={item.placeholder}
          httpAllowed={item.httpAllowed}
          {...rest}
        />
      );
  }
};
