import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";

import { ConfigValues } from "../../api/types/config-values";
import { isAnyFormConditionValid } from "../utils/conditions-check-in-form";
import { considerItemAsNew } from "../utils/consider-item-as-new";
import { formItemValueName } from "../utils/form-item-value-name";
import { formItemVisibilityName } from "../utils/form-item-visibility-name";

interface GenerateAdjustOldConfigValuesToNewShielderFunctionParams {
  oldItems: ConfigurationItemType[];
  newItems: ConfigurationItemType[];
}
export const generateAdjustOldConfigValuesToNewShielderFunction =
  ({ oldItems, newItems }: GenerateAdjustOldConfigValuesToNewShielderFunctionParams) =>
  (oldConfigValues: ConfigValues) => {
    if (!oldConfigValues) return undefined;

    const newConfigValues: ConfigValues = { debugMode: oldConfigValues.debugMode };

    for (const newItem of newItems) {
      const oldItem = oldItems.find(item => item.name === newItem.name);
      const isChangedItem = oldItem && considerItemAsNew(newItem, oldItem);

      newConfigValues[formItemValueName(newItem.name)] =
        oldItem && !isChangedItem
          ? oldConfigValues[formItemValueName(newItem.name)]
          : (newItem.data.defaultValue ?? null);

      newConfigValues[formItemVisibilityName(newItem.name)] =
        !newItem.invisible || !isAnyFormConditionValid(newConfigValues, newItem.invisible);
    }

    return newConfigValues;
  };
