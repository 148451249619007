import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";

import { formItemValueName } from "../utils/form-item-value-name";
import { formItemVisibilityName } from "../utils/form-item-visibility-name";

export function generateDefaultConfiguration(items: ConfigurationItemType[]) {
  return {
    debugMode: false,
    ...Object.fromEntries(
      items.flatMap(item => [
        [formItemValueName(item.name), item.data.defaultValue ?? null],
        [formItemVisibilityName(item.name), true],
      ]),
    ),
  };
}
