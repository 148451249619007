import { ConfigurationItem as ConfigurationItemType, DataType, Widget } from "@mob/shielder-metadata";
import { z } from "zod";

import { ConfigValues } from "../../api/types/config-values";
import { FileCertificate } from "../../api/types/file-certificate";
import { conditionalParameterValidationMessage } from "../utils/conditional-parameter-validation-message";
import { formItemValueName } from "../utils/form-item-value-name";
import {
  conditionallyAllowedValues,
  eachLineMaxLength,
  eachLineRegExp,
  isAnyConditionValid,
  maxLength,
  maxLinesCount,
  maxValue,
  minValue,
  regExp,
  required,
  urlProtocol,
  validate,
} from "../utils/validators";

const fmtFormNumber = <T>(value: T) => (typeof value !== "string" ? value : value === "" ? null : +value);

const TYPES: Record<DataType, z.ZodTypeAny> = {
  [DataType.String]: z.string(),
  [DataType.Integer]: z.union([z.number().nullable(), z.string()]),
  // number comes from the server, it becomes a string when
  // it is changed by user in the UI
  [DataType.File]: z.union([z.instanceof(File).nullable(), FileCertificate]),
  [DataType.Boolean]: z.union([z.boolean().nullable(), z.string()]),
};

export type ConfigurationFormSchema = ReturnType<typeof generateConfigurationFormSchema>;
export function generateConfigurationFormSchema(items: ConfigurationItemType[]) {
  let schema: z.ZodTypeAny = z.object({
    debugMode: z.boolean(),
    ...Object.fromEntries(
      items.map(item => {
        let schema = TYPES[item.data.type];

        if ([DataType.String, DataType.Integer].includes(item.data.type)) {
          schema = schema.transform((value: string | number) => (typeof value === "string" ? value.trim() : value));
        }

        // all formItemVisibilityName parameters will be stripped out
        return [formItemValueName(item.name), schema];
      }),
    ),
  });

  // Transform configuration form to have only visible items with item.name as the key
  schema = schema.transform((form: ConfigValues, { addIssue }) => {
    const transformedForm: ConfigValues = { debugMode: form.debugMode };

    for (const item of items) {
      const { name, invisible, widget } = item;
      const shouldBeVisible = !invisible || !isAnyConditionValid(transformedForm, invisible);

      if (shouldBeVisible) {
        if (!(formItemValueName(name) in form)) {
          // This should never happen since widget UI logic should be in accord with transformation logic
          // but just for in case
          addIssue({
            code: z.ZodIssueCode.custom,
            message: conditionalParameterValidationMessage(name, true),
            path: [name],
          });
        }

        transformedForm[name] = form[formItemValueName(name)];
      } else if (widget === Widget.CertificateUploader) {
        // If certificate field invisible, it means it should not be present,
        // therefore it should be deleted from the server if it is present
        transformedForm[`${name}Delete`] = true;
      }
    }

    return transformedForm;
  });

  schema = schema.transform((sourceForm: ConfigValues, ctx: z.RefinementCtx) => {
    const form = { ...sourceForm };

    for (const item of items) {
      // All non-visible items are already stripped out from the form
      // so iterate only through visible items (those that present in the form)
      if (item.name in form) {
        const { name, widget, debugModeOnly, data } = item;

        if (debugModeOnly && !form.debugMode && form[name] !== item.data.defaultValue) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `${item.name} should be equal to "${item.data.defaultValue}" when not in Debug Mode`,
            path: [name],
          });
        }

        switch (widget) {
          case Widget.CertificateUploader:
            validate({ form, ctx, name }, required(item.validations.required, "Certificate file"));
            break;

          case Widget.Checkbox:
            validate({ form, ctx, name }, conditionallyAllowedValues(item.validations?.allowedValue));
            break;

          case Widget.ColorInput:
            validate(
              { form, ctx, name },
              required(item.validations?.required, item.label),
              regExp({ value: "^([0-9a-fA-F]{3}){1,2}$", message: "Is not a hex color code" }),
            );
            form[name] = (form[name] as string).toUpperCase();
            break;

          case Widget.Dropdown:
          case Widget.RadioGroup:
            validate(
              { form, ctx, name },
              conditionallyAllowedValues<boolean | string | number>(item.data.validations?.allowedValue),
            );

            switch (data.type) {
              case DataType.Boolean:
                form[name] = form[name] === true || form[name] === "true";
                break;

              case DataType.Integer:
                form[name] = fmtFormNumber(form[name]);
                break;
            }
            break;

          case Widget.Input:
            validate(
              { form, ctx, name },
              required(item.validations?.required, item.label),
              maxLength(item.validations?.maxLength),
              regExp(item.validations?.regExp),
            );
            break;

          case Widget.MultilineInput:
            validate(
              { form, ctx, name },
              required(item.validations?.required, item.label),
              maxLength(item.validations?.maxLength),
              regExp(item.validations?.regExp),
              maxLinesCount(item.validations?.maxLinesCount),
              eachLineMaxLength(item.validations?.eachLine?.maxLength),
              eachLineRegExp(item.validations?.eachLine?.regExp),
            );
            break;

          case Widget.NumberInput:
            validate(
              { form, ctx, name },
              required(item.validations?.required, item.label),
              regExp({ value: "^\\d+$", message: "Is not a number" }),
              minValue(item.validations?.minValue),
              maxValue(item.validations?.maxValue),
            );
            form[name] = fmtFormNumber(form[name]);
            break;

          case Widget.Url:
            validate(
              { form, ctx, name },
              required(item.validations?.required, item.label),
              urlProtocol(item.httpAllowed),
            );
            break;
        }
      }
    }

    return form;
  });

  return schema;
}
