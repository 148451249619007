import { alpha, Box } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { formItemValueName } from "../../../../configuration-schema/utils/form-item-value-name";
import { InputItem, InputItemProps } from "./input-item";

type ColorItemProps = Omit<InputItemProps, "validations" | "helperText">;

export function ColorItem(props: ColorItemProps) {
  const { getValues } = useFormContext();
  const [color, setColor] = useState(getValues(formItemValueName(props.name)) as string);
  const { classes } = useStyles({ color });

  const changeDecorator = useCallback(
    (handler: ChangeEventHandler<HTMLInputElement>) => (event: ChangeEvent<HTMLInputElement>) => {
      if (/^([0-9a-fA-F]*)$/.test(event.target.value)) {
        setColor(event.target.value.toUpperCase());
        handler(event);
      }
    },
    [],
  );

  return (
    <Box className={classes.container}>
      <InputItem
        {...props}
        startAdornment={<Box className={classes.adornment} />}
        onChangeDecorator={changeDecorator}
        uppercase
        inputProps={{ maxLength: 6 }}
      />
    </Box>
  );
}

const useStyles = makeStyles<{ color: string }>()((theme, { color = "FFFFFF" }) => ({
  container: {
    "& label + div": {
      maxWidth: 256,
    },
  },
  adornment: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: 2,
    backgroundColor: `#${color}`,
    marginLeft: theme.spacing(1),
    border: `1px solid ${alpha(theme.palette.text.primary, 0.79)}`,
  },
}));
