import { useEffect } from "react";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";

import { isValidationErrors, MutationError } from "./mutation-error";

export const useServerFormValidationErrors = <TFieldValues extends FieldValues>(
  formProps: UseFormReturn<TFieldValues>,
  errors: MutationError<TFieldValues> | null | undefined,
  formFieldMapper?: (name: string) => string,
) => {
  useEffect(() => {
    if (isValidationErrors(errors) && errors.hasFieldErrors()) {
      for (const key of errors.fieldsWithErrors) {
        const fieldName = (formFieldMapper ? formFieldMapper(key) : key) as Path<TFieldValues>;
        formProps.setError(fieldName, {
          type: "manual",
          message: errors.fieldErrorsMessage(key),
        });
      }
    }
  }, [formProps, errors, formFieldMapper]);
  return isValidationErrors(errors) && errors.hasGeneralErrors ? errors.generalErrorsMessage : undefined;
};
