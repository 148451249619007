import {
  AllowedValueValidation,
  MaxLengthValidation,
  NaturalNumberParamValidation,
  Reference,
  RegExpValidation,
  RequiredValidation,
} from "@mob/shielder-metadata";
import { z } from "zod";

import { i18n } from "../../../../../../_common/i18n/i18n";
import { isNotFalsy } from "../../../../../../_common/utils/is-not-falsy";
import { ConfigValues } from "../../api/types/config-values";
import { formItemValueName } from "./form-item-value-name";

function validateEachLine(
  validation: NaturalNumberParamValidation | RegExpValidation | undefined,
  validator: (line: string) => boolean,
  messageKey?: string,
) {
  return validation
    ? (value: string) => {
        const errors = value
          ? value
              .split("\n")
              .map((line, index) => (validator(line) ? null : index + 1))
              .filter(isNotFalsy)
          : [];
        return errors.length === 0
          ? ""
          : validation.message
            ? `${validation.message} (on line${errors.length > 1 ? "s" : ""}: ${errors.join(", ")})`
            : i18n.intl.formatMessage(
                { id: `component.configuration-form.item.validation.each-line.${messageKey}.message` },
                { line: errors.join(", ") },
              );
      }
    : null;
}

export function validate<Type>(
  { form, ctx, name }: { form: ConfigValues; ctx: z.RefinementCtx; name: string },
  ...validators: (((value: Type, form: ConfigValues, name: string) => string) | null)[]
) {
  const message = validators
    .filter(isNotFalsy)
    .map(validator => validator(form[name] as Type, form, name))
    .filter(isNotFalsy)
    .join(". ");

  if (message) {
    ctx.addIssue({ code: z.ZodIssueCode.custom, message, path: [formItemValueName(name)] });
  }
}

export const required = (validation: RequiredValidation | undefined, label: string) =>
  validation
    ? (value: string | number | File | null) =>
        value === null || value === ""
          ? ((typeof validation === "object" ? validation.message : null) ??
            i18n.intl.formatMessage(
              { id: "component.configuration-form.item.validation.required.message" },
              { label: label.split(" ").length > 2 ? "The value" : label },
            ))
          : ""
    : null;

export const maxLength = (validation: MaxLengthValidation | undefined) =>
  validation
    ? (value: string) =>
        value && value.length > validation.value
          ? i18n.intl.formatMessage(
              { id: "component.configuration-form.item.validation.max-length.message" },
              { value: validation.value },
            )
          : ""
    : null;

export const regExp = (validation: RegExpValidation | undefined) =>
  validation ? (value: string) => (value && !new RegExp(validation.value).test(value) ? validation.message : "") : null;

export const maxLinesCount = (validation: NaturalNumberParamValidation | undefined) =>
  validation
    ? (value: string) =>
        value && value.split("\n").length > validation.value
          ? (validation.message ??
            i18n.intl.formatMessage(
              { id: "component.configuration-form.item.validation.max-lines-count.message" },
              { value: validation.value },
            ))
          : ""
    : null;

export const eachLineMaxLength = (validation: NaturalNumberParamValidation | undefined) =>
  validateEachLine(validation, line => line.length <= validation!.value, "max-length");

export const eachLineRegExp = (validation: RegExpValidation | undefined) =>
  validateEachLine(validation, line => new RegExp(validation!.value).test(line));

export const urlProtocol = (allowHttp: boolean | undefined) => (value: string) =>
  !value || value.startsWith("https://") || (allowHttp && value.startsWith("http://"))
    ? ""
    : `URL has to start with https://${allowHttp ? " or http://" : ""}`;

export const maxValue = (validation: NaturalNumberParamValidation | undefined) =>
  validation
    ? (value: string) =>
        value && isFinite(+value) && +value > validation.value
          ? (validation.message ??
            i18n.intl.formatMessage(
              { id: "component.configuration-form.item.validation.max-value.message" },
              { value: validation.value },
            ))
          : ""
    : null;

export const minValue = (validation: NaturalNumberParamValidation | undefined) =>
  validation
    ? (value: string) =>
        value && isFinite(+value) && +value < validation.value
          ? (validation.message ??
            i18n.intl.formatMessage(
              { id: "component.configuration-form.item.validation.min-value.message" },
              { value: validation.value },
            ))
          : ""
    : null;

export const conditionallyAllowedValues = <T extends boolean | string | number>(
  validation: AllowedValueValidation<T> | undefined,
) =>
  validation
    ? (value: T, form: ConfigValues) =>
        value !== validation.value && isConditionValid(form, validation.condition) ? validation.message : ""
    : null;

function isConditionValid(form: ConfigValues, condition: Reference) {
  const visible = condition.name in form;
  const conditionResult = visible && form[condition.name] === condition.value;

  return condition.negate ? !conditionResult : conditionResult;
}

export function isAnyConditionValid(form: ConfigValues, conditions: Reference[]) {
  return conditions.some(condition => isConditionValid(form, condition));
}
