import { Reference } from "@mob/shielder-metadata";

import { ConfigValues } from "../../api/types/config-values";
import { formItemValueName } from "./form-item-value-name";
import { formItemVisibilityName } from "./form-item-visibility-name";

function isFormConditionValid(form: ConfigValues, condition: Reference) {
  // Parsed server response, and UI form would have values for all items (visible and invisible),
  // therefore `formItemVisibilityName` is used to check visibility
  // Submitting form would have only visible values (all invisible values are deleted), therefore
  // the presence of the item name key in the form is checked
  const visible = formItemValueName(condition.name) in form && form[formItemVisibilityName(condition.name)];
  const conditionResult = visible && form[formItemValueName(condition.name)] === condition.value;

  return condition.negate ? !conditionResult : conditionResult;
}
export function isAnyFormConditionValid(form: ConfigValues, conditions: Reference[]) {
  return conditions.some(condition => isFormConditionValid(form, condition));
}
