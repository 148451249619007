import { datadogRum } from "@datadog/browser-rum";
import { useEffect, useState } from "react";

import { version } from "../../../../package.json";
import { useAuthUserProfile } from "../../auth/auth-provider/auth-user-profile-context";
import { ENV } from "../../env-vars";

export const useDatadog = () => {
  const [isDatadogInitialized, setDatadogInitialized] = useState(false);
  const { id: userId, accountId, company, roles } = useAuthUserProfile();

  useEffect(() => {
    // Avoid running e2e tests with DataDog enabled as each test creates a new session and is billed separately
    if (
      (ENV.ENVIRONMENT === "production" || ENV.ENVIRONMENT === "qa") &&
      ENV.DATADOG_APPLICATION_ID &&
      ENV.DATADOG_CLIENT_TOKEN &&
      !isDatadogInitialized
    ) {
      // DataDog Documentation:
      // https://docs.datadoghq.com/getting_started
      // https://docs.datadoghq.com/real_user_monitoring/browser/setup/
      datadogRum.init({
        applicationId: ENV.DATADOG_APPLICATION_ID,
        clientToken: ENV.DATADOG_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "us5.datadoghq.com",
        service: "mobile.onespan.com",
        env: ENV.ENVIRONMENT,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackingConsent: "not-granted",
        trackUserInteractions: false,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });

      setDatadogInitialized(true);
    }

    if (isDatadogInitialized && userId) {
      datadogRum.setUser({
        id: userId,
        company,
        accountId,
        appShielding: { online: roles.hasAppShielding, offline: roles.hasOnPremShielders },
      });
    }
  }, [accountId, company, userId, isDatadogInitialized, roles.hasAppShielding, roles.hasOnPremShielders]);

  return isDatadogInitialized;
};
