import { AxiosError } from "axios";
import { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { logError } from "../error-logging/log-error";
import { useTranslate } from "../i18n/hooks/use-translate.hook";
import { Spinner } from "../ui/spinner/spinner";
import { useAuthActions } from "./auth-provider/auth-actions-context";
import { CognitoAuthMethod, exchangeCodeForToken, redirectToCognito } from "./congito-api/cognito-api";
import { popLocation } from "./storages/location-storage";
import { setTokens } from "./storages/token-storage/set-tokens";

export const CallbackPage = () => {
  const t = useTranslate();
  const code = useSearchParams()[0].get("code");
  const { authenticationConfirmed } = useAuthActions();
  const exchangeRequestSentRef = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!code) {
      redirectToCognito(CognitoAuthMethod.Login);
    } else if (!exchangeRequestSentRef.current) {
      exchangeRequestSentRef.current = true;
      exchangeCodeForToken(code)
        .then(tokens => {
          setTokens(tokens);
          authenticationConfirmed();
          navigate(popLocation() ?? "/");
        })
        .catch((error: AxiosError) => {
          logError(error, undefined, "Error while exchanging code for token");
          redirectToCognito(CognitoAuthMethod.Login);
        });
    }
  }, [authenticationConfirmed, code, navigate]);

  return <Spinner>{t("component.auth.authorizing.message")}</Spinner>;
};
