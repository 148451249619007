import { ConfigurationItem, Widget } from "@mob/shielder-metadata";
import { Box, InputBaseComponentProps } from "@mui/material";
import { ChangeEvent, ChangeEventHandler, useCallback } from "react";
import { useController } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { FormFieldLabel } from "../../../../../../../../_common/ui/form-field/form-field-label";
import { InputCharacterCounter } from "../../../../../../../../_common/ui/form-input/input-character-counter";
import { InputHelperTextContent } from "../../../../../../../../_common/ui/input/input-helper-text-content";
import { TextInput } from "../../../../../../../../_common/ui/text-input/text-input";
import { formItemValueName } from "../../../../configuration-schema/utils/form-item-value-name";
import { HelpButton } from "../../../../ui/help-button";
import { ItemReadOnlyValue } from "../../../../ui/item-read-only-value";
import { ariaLabelledById } from "../../../../utils/aria-labelled-by-id";
import { helperTextId } from "../../../../utils/helper-text-id";
import { itemId } from "../../../../utils/item-id";
import { validationHelperText } from "./utils/validation-helper-text";

export interface InputItemProps {
  first: boolean;
  multiline?: boolean;
  name: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  uppercase?: boolean;
  onChangeDecorator?: (handler: ChangeEventHandler<HTMLInputElement>) => (event: ChangeEvent<HTMLInputElement>) => void;
  validations?: Extract<ConfigurationItem, { widget: Widget.Input } | { widget: Widget.MultilineInput }>["validations"];
  startAdornment?: React.ReactNode;
  toggleDescription: (() => void) | undefined;
  isDescriptionVisible: boolean;
  inputProps?: InputBaseComponentProps;
}

export function InputItem({
  first,
  multiline,
  name,
  label,
  placeholder,
  helperText,
  disabled,
  onChangeDecorator,
  isUpdating,
  isEditMode,
  validations,
  startAdornment,
  uppercase,
  toggleDescription,
  isDescriptionVisible,
  inputProps,
}: InputItemProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({ name: formItemValueName(name) });
  const strValue = (value as unknown)?.toString() ?? "";

  const status = error ? "error" : undefined;
  const { classes } = useStyles({ uppercase });

  const { regExp, maxLength, maxLinesCount, eachLine } = validations ?? {};
  const fullHelperText = [
    helperText,
    validationHelperText(regExp),
    validationHelperText(maxLinesCount, "max-lines-count"),
    validationHelperText(eachLine?.regExp),
    validationHelperText(eachLine?.maxLength, "each-line.max-length"),
  ]
    .filter(Boolean)
    .join(". ");

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const changeHandler = useCallback(onChangeDecorator ? onChangeDecorator(onChange) : onChange, [
    onChangeDecorator,
    onChange,
  ]);

  return isEditMode ? (
    <Box display="flex" flexDirection="column" gap={0.5}>
      {!first && (
        <>
          <Box display="flex" flexDirection="row" alignItems="center" mb={0.5}>
            <FormFieldLabel htmlFor={itemId(name)}>{label}</FormFieldLabel>
            {toggleDescription && (
              <HelpButton
                itemLabel={label}
                aria-controls={helperTextId(name)}
                aria-expanded={isDescriptionVisible}
                onClick={toggleDescription}
              />
            )}
          </Box>
        </>
      )}
      <TextInput
        id={itemId(name)}
        onChange={changeHandler}
        {...field}
        value={strValue}
        placeholder={placeholder}
        disabled={disabled || isUpdating}
        status={status}
        aria-labelledby={ariaLabelledById(name)}
        inputProps={{
          "data-testid": `input-${name}`,
          maxLength: maxLength?.value,
          "aria-label": first ? label : undefined,
          ...inputProps,
        }}
        className={classes.input}
        multiline={multiline}
        startAdornment={startAdornment}
        spellCheck={false}
      />
      <InputHelperTextContent
        inputName={name}
        status={status}
        helperText={error?.message ?? fullHelperText}
        afterAddon={<InputCharacterCounter value={strValue.length} max={maxLength?.value} />}
      />
    </Box>
  ) : (
    <ItemReadOnlyValue uppercase={uppercase} value={strValue} />
  );
}

const useStyles = makeStyles<Pick<InputItemProps, "uppercase">>()((_, { uppercase }) => ({
  input: {
    "& input": {
      textTransform: uppercase ? "uppercase" : undefined,
    },
  },
}));
