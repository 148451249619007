import type { ConfigurationItemVisibility as ConfigurationItemVisibilityType } from "@mob/shielder-metadata";
import { ReactNode, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { formItemValueName } from "../../../configuration-schema/utils/form-item-value-name";
import { formItemVisibilityName } from "../../../configuration-schema/utils/form-item-visibility-name";

interface ConfigurationItemVisibilityProps {
  invisible: ConfigurationItemVisibilityType;
  name: string;
  children: ReactNode;
}
export const ConfigurationItemVisibility = ({ invisible, name, children }: ConfigurationItemVisibilityProps) => {
  const values = useWatch({
    disabled: !invisible,
    ...(invisible ? { name: invisible.flatMap(i => [formItemValueName(i.name), formItemVisibilityName(i.name)]) } : {}),
  });
  const { clearErrors, trigger, formState, setValue } = useFormContext();
  const { isSubmitted } = formState;

  const hideItem = invisible?.some(({ negate, value }, index) => {
    const conditionResult = Boolean(values[index * 2 + 1]) && values[index * 2] === value;
    return negate ? !conditionResult : conditionResult;
  });

  useEffect(() => {
    if (invisible) {
      setValue(formItemVisibilityName(name), !hideItem);
      if (hideItem) clearErrors(name);
      else if (isSubmitted) void trigger(name);
    }
  }, [clearErrors, hideItem, invisible, isSubmitted, name, setValue, trigger]);

  return hideItem ? null : <>{children}</>;
};
