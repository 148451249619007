import { ConfigurationItem as ConfigurationItemType, DataType } from "@mob/shielder-metadata";
import { z } from "zod";

import { ConfigValues } from "../../api/types/config-values";
import { FileCertificate } from "../../api/types/file-certificate";
import { conditionalParameterValidationMessage } from "../utils/conditional-parameter-validation-message";
import { isAnyFormConditionValid } from "../utils/conditions-check-in-form";
import { formItemValueName } from "../utils/form-item-value-name";
import { formItemVisibilityName } from "../utils/form-item-visibility-name";

const TYPES: Record<DataType, z.ZodTypeAny> = {
  [DataType.String]: z.string(),
  [DataType.Integer]: z.number().nullable(),
  [DataType.File]: FileCertificate,
  [DataType.Boolean]: z.boolean().nullable(),
};

type GenerateConfigurationResponseSchemaParams = {
  strip: boolean;
};
// Creates zod schema to validate data types, presence/absence of the fields,
// transforms each field into 2 fields: `<fieldName>Value` and `<fieldName>Visible`
export function generateConfigurationResponseSchema(
  items: ConfigurationItemType[],
  { strip }: GenerateConfigurationResponseSchemaParams = { strip: false },
) {
  const obj = z.object(
    Object.fromEntries(
      items.flatMap(item => {
        let itemSchema = TYPES[item.data.type];

        if (item.invisible) {
          itemSchema = itemSchema.optional();
        }

        return [[item.name, itemSchema]];
      }),
    ),
  );

  const schema = strip ? obj : obj.strict();

  return schema.transform((form: ConfigValues, ctx) => {
    const transformedForm: ConfigValues = {};

    for (const item of items) {
      const { name, invisible } = item;

      const valuePresent = name in form;
      const shouldBeVisible = !invisible || !isAnyFormConditionValid(transformedForm, invisible);

      // Only fields with `invisible` property were defined as optional, and require
      // the error messages to be generated.
      // Fields that don't have `invisible` property are not optional, so
      // their presence is validated already by zod schema.
      // Therefore don't generate error messages for them, only generate if `invisible` is present.
      if (invisible && (shouldBeVisible ? !valuePresent : valuePresent && !strip)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: conditionalParameterValidationMessage(name, shouldBeVisible),
          path: [name],
        });
      }

      if (shouldBeVisible) {
        if (valuePresent) {
          // Keep the value, just rename the field
          transformedForm[formItemValueName(name)] = form[name];
        }
      } else {
        // The item is not visible now, and doesn't have any value.
        // If it will become visible, it should have default value.
        // So assign default value - it will be ignored if it while it is invisible.
        // If item has no default value (ex. certificate), assign null in order the form not to complain
        transformedForm[formItemValueName(name)] = item.data.defaultValue ?? null;
      }
      transformedForm[formItemVisibilityName(name)] = shouldBeVisible;
    }

    return transformedForm;
  });
}
