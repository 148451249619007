import { datadogRum } from "@datadog/browser-rum";
import { AxiosError } from "axios";

export const logError = (error: unknown, context?: object, reason: string = "Error") => {
  const isDatadogEnabled = Boolean(datadogRum.getInternalContext());

  if (!isDatadogEnabled) {
    /* eslint-disable no-console */
    console.group(
      `%c${reason}:`,
      "border-radius: 3px; background: #CC3333; color: white; font-size: 11px; padding: 3px",
    );
    console.log(error);
    if (context) {
      console.log("Context", context);
    }
    console.trace("Trace:");
    console.groupEnd();
    /* eslint-enable no-console */
  } else {
    let datadogContext = { ...context };

    if (error instanceof AxiosError) {
      const baseUrl = error.config?.baseURL || "";
      const url = error.config?.url || "";

      datadogContext = { ...datadogContext, apiUrl: `${baseUrl}${url}` };
    }

    datadogRum.addError(error, datadogContext);
  }
};
