import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getManyWithPagination } from "../../../../../_common/api/methods/get-many-with-pagination";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { ProjectI18nToastKey } from "./_support/project-i18n-toast-key";
import { ProjectsListParam } from "./_support/projects-query-keys-factory";
import { ApiGetProject, getProjectTransformer } from "./get-project";

export const ApiGetProjectsListItem = ApiGetProject;
export type ApiGetProjectsListItem = z.input<typeof ApiGetProjectsListItem>;

export const GetProjectsListItem = ApiGetProjectsListItem.transform(getProjectTransformer);
export type GetProjectsListItem = z.infer<typeof GetProjectsListItem>;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UseGetProjectsList extends ProjectsListParam {}

export const useGetProjectsList = (params: UseGetProjectsList) => {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].projects().list(params),
    queryFn: async () =>
      getManyWithPagination(
        restClient,
        ApiEntity.Project,
        {
          page: params.page,
          orderBy: params.sortingParams.sortColumn,
          order: params.sortingParams.sortOrder,
          itemsPerPage: params.itemsPerPage,
        },
        GetProjectsListItem,
      ),
    meta: { i18nToastKey: ProjectI18nToastKey.GET_PROJECTS_LIST },
    select: data => ({ projects: data?.data, totalCount: data?.pagination.total }),
  });
};
