import { getMetadataItems, ShielderMetadata, Widget } from "@mob/shielder-metadata";
import { useMemo } from "react";

import { isNotFalsy } from "../../../../../_common/utils/is-not-falsy";
import { generateAdjustOldConfigValuesToNewShielderFunction } from "./generators/generate-adjust-old-onfig-values-to-new-shielder-function";
import { generateConfigurationFormSchema } from "./generators/generate-configuration-form-schema";
import { generateConfigurationResponseSchema } from "./generators/generate-configuration-response-schema";
import { generateDefaultConfiguration } from "./generators/generate-default-configuration";
import { generateMetadataFeatureValues } from "./generators/generate-metadata-feature-values";
import { generateResetDebugOnlyFieldsFunction } from "./generators/generate-reset-debug-only-fields-function";
import { getNewConfigurationItemsFromNewShielder } from "./generators/get-new-configuration-items-from-new-shielder";
import { getValidationDependencies } from "./generators/get-validation-dependencies";

export function useConfigurationSchema(
  newShielderMetadata: ShielderMetadata | undefined,
  oldShielderMetadata: ShielderMetadata | undefined,
) {
  return useMemo(() => {
    if (!newShielderMetadata || !oldShielderMetadata) return {};

    const newItems = getMetadataItems(newShielderMetadata);
    const oldItems = getMetadataItems(oldShielderMetadata);

    return {
      allItems: newItems.map(item => item.name),
      certificateFields: newItems
        .map(item => (item.widget === Widget.CertificateUploader ? item.name : null))
        .filter(isNotFalsy),
      configurationFormSchema: generateConfigurationFormSchema(newItems),
      configurationResponseNewSchema: generateConfigurationResponseSchema(newItems),
      configurationResponseOldSchema: generateConfigurationResponseSchema(oldItems),
      debuggable: newItems.some(item => item.debugModeOnly),
      featureValues: generateMetadataFeatureValues(newShielderMetadata),
      adjustOldConfigValuesToNewShielder: generateAdjustOldConfigValuesToNewShielderFunction({ newItems, oldItems }),
      newDefaultConfiguration: generateDefaultConfiguration(newItems),
      newItems: getNewConfigurationItemsFromNewShielder(oldItems, newItems),
      resetDebugOnlyFields: generateResetDebugOnlyFieldsFunction(newItems),
      validationDependencies: getValidationDependencies(newItems),
    };
  }, [newShielderMetadata, oldShielderMetadata]);
}
