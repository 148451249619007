import { Box, FormControl, FormControlLabel } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { Checkbox } from "../../../../../../../../_common/ui/checkbox/checkbox";
import { formItemValueName } from "../../../../configuration-schema/utils/form-item-value-name";
import { HelpButton } from "../../../../ui/help-button";
import { ItemReadOnlyValue } from "../../../../ui/item-read-only-value";
import { ItemValidationMessage } from "../../../../ui/item-validation-message";
import { ariaLabelledById } from "../../../../utils/aria-labelled-by-id";
import { helperTextId } from "../../../../utils/helper-text-id";
import { itemId } from "../../../../utils/item-id";

interface CheckboxItemProps {
  first: boolean;
  disabled?: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  name: string;
  label: string;
  validationDependency: string[] | undefined;
  toggleDescription: (() => void) | undefined;
  isDescriptionVisible: boolean;
}

export function CheckboxItem({
  first,
  name,
  label,
  disabled,
  validationDependency,
  toggleDescription,
  isEditMode,
  isUpdating,
  isDescriptionVisible,
}: CheckboxItemProps) {
  const {
    formState: { isSubmitted },
    trigger,
  } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({ name: formItemValueName(name) });

  const { classes } = useStyles();
  return isEditMode ? (
    <>
      <FormControl className={classes.formControl}>
        <Box display="flex" alignItems="center">
          <FormControlLabel
            className={classes.label}
            control={
              <Checkbox
                id={itemId(name)}
                name={field.name}
                checked={field.value as boolean}
                onChange={e => {
                  field.onChange(e.target.checked);
                  if (isSubmitted && validationDependency)
                    validationDependency.forEach(name => void trigger(formItemValueName(name)));
                }}
                onBlur={field.onBlur}
                inputRef={field.ref}
                disabled={disabled || isUpdating}
                aria-labelledby={error ? ariaLabelledById(name) : undefined}
                aria-label={first ? label : undefined}
              />
            }
            label={first ? "Enable" : label}
          />
          {!first && toggleDescription && (
            <HelpButton
              aria-controls={helperTextId(name)}
              aria-expanded={isDescriptionVisible}
              itemLabel={label}
              onClick={toggleDescription}
            />
          )}
        </Box>
      </FormControl>
      <ItemValidationMessage itemName={name} error={error} />
    </>
  ) : (
    <ItemReadOnlyValue value={field.value ? "Enabled" : "Disabled"} />
  );
}

const useStyles = makeStyles()(theme => ({
  formControl: {
    flexDirection: "column",
  },
  label: {
    marginLeft: 0,
    marginRight: 0,
    wordBreak: "break-word",
    "& .MuiCheckbox-root": {
      marginRight: theme.spacing(1.275),
      "&.Mui-checked": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiTypography-root": {
      lineHeight: "20px",
    },
  },
}));
