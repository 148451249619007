import { Box } from "@mui/material";
import { useCallback, useRef } from "react";
import { Navigate, Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../_common/datadog";
import { LanguageKeys } from "../../../../_common/i18n/dictionary/en";
import { useTranslate } from "../../../../_common/i18n/hooks/use-translate.hook";
import { ApplicationBarSecondaryButton } from "../../../../_common/ui/application-bar/application-bar-secondary-button";
import { ApplicationBarIconButtonType } from "../../../../_common/ui/application-bar/types";
import { Layout } from "../../../../_common/ui/layout/layout";
import { TextSkeleton } from "../../../../_common/ui/skeleton/text-skeleton";
import { appShieldingRootPath } from "../../app-shielding-root-path";
import { useGetProject } from "../projects/api/get-project";
import { useGetShielder } from "../shielders/api/get-shielder";
import { AlertType } from "../shielders/types/alert-type";
import { ShielderStatus } from "../shielders/types/shielder-status";
import { UnsupportedOrDeletedShielderVersionAlert } from "../shielders/ui/unsupported-or-deleted-shielder-version-alert";
import { ConfigurationI18nToastKey } from "./api/_support/configuration-i18n-toast-key";
import { useGetConfiguration } from "./api/get-configuration";
import { useReplaceConfiguration } from "./api/replace-configuration/use-replace-configuration";
import { ConfigStatus } from "./api/types/config-status";
import { ConfigValues } from "./api/types/config-values";
import { ConfigurationEditButton } from "./configuration-form/configuration-edit-button";
import { ConfigurationFormContent } from "./configuration-form/configuration-form-content";
import { ConfigurationSubmitButton } from "./configuration-form/configuration-submit-button";
import { ConfigurationValidationCountAlert } from "./configuration-form/validation-count-alert/configuration-validation-count-alert";
import { ConfigurationFormProvider } from "./configuration-form-provider";
import { useConfigurationSchema } from "./configuration-schema/use-configuration-schema";
import { PageType } from "./page-type";
import { ConfigurationDebugModeTag } from "./ui/configuration-debug-mode-tag";
import { DebugModeSwitch } from "./ui/debug-mode-switch";
import { NewShielderItemsAlert } from "./ui/new-shielder-items-alert";

const titleTranslationKey: Record<PageType, LanguageKeys> = {
  [PageType.Show]: `component.view-configuration.page-title`,
  [PageType.Edit]: `component.edit-configuration.page-title`,
  [PageType.New]: `component.new-configuration.page-title`,
};

interface ConfigurationPageProps {
  type: PageType;
  changeVersion?: boolean;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export const ConfigurationPage = ({ type: pageType, changeVersion }: ConfigurationPageProps) => {
  const navigate = useNavigate();
  const t = useTranslate();

  const projectId = useParams<"projectId">().projectId!;
  const newShielderId = useSearchParams()[0].get("new-version") ?? "";

  const { data: project } = useGetProject(projectId);
  const { data: oldShielder } = useGetShielder(project);
  const { data: newShielder } = useGetShielder({ shielderId: changeVersion ? newShielderId : project?.shielderId });

  const {
    allItems,
    certificateFields,
    configurationResponseOldSchema,
    configurationResponseNewSchema,
    configurationFormSchema,
    debuggable,
    featureValues,
    adjustOldConfigValuesToNewShielder: adjustOldConfigValuesToNewShielder,
    newDefaultConfiguration,
    newItems,
    resetDebugOnlyFields,
    validationDependencies,
  } = useConfigurationSchema(newShielder?.shielderMetadata, oldShielder?.shielderMetadata);

  const { data: configuration } = useGetConfiguration({
    projectId,
    projectName: project?.projectName,
    configurationId: project?.configId,
    disabled: pageType === PageType.New,
    configurationResponseSchema: configurationResponseOldSchema,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { configUpdatedAt, configValues } = configuration ?? {};

  const closePage = useCallback(() => {
    navigate(pageType === PageType.New ? "../.." : "..");
  }, [navigate, pageType]);

  const {
    mutateAsync: replaceConfigurationAsync,
    isPending: isMutating,
    error: mutationError,
  } = useReplaceConfiguration(
    { projectId: project?.id, configId: project?.configId, projectName: project?.projectName },
    pageType === PageType.New ? ConfigurationI18nToastKey.CREATE_CONFIG : ConfigurationI18nToastKey.UPDATE_CONFIG,
    configurationResponseNewSchema,
    changeVersion ?? false,
    configValues as ConfigValues | undefined,
    certificateFields,
  );

  const isLoadingMetadata =
    !project ||
    !newShielder ||
    !oldShielder ||
    newShielder.shielderPlatform !== oldShielder.shielderPlatform ||
    !newDefaultConfiguration ||
    !configurationFormSchema ||
    !adjustOldConfigValuesToNewShielder;

  const isLoadingConfiguration = pageType !== PageType.New && !configValues;
  const isUnsupportedShielder = newShielder?.shielderStatus === ShielderStatus.Unsupported;
  const isDeletedShielder = newShielder?.shielderStatus === ShielderStatus.Deleted;
  const hasApiKey = project?.apiKeyId !== undefined;

  const isLoading = isLoadingMetadata || isLoadingConfiguration;

  /**
   * Navigation validation logic
   */
  const firstTimeOnThePageRef = useRef(true);
  if (project && firstTimeOnThePageRef.current) {
    switch (pageType) {
      case PageType.Show:
        if (project.configStatus === ConfigStatus.None) {
          return <Navigate replace to="new" />;
        }
        break;

      case PageType.Edit:
        if (project.configStatus === ConfigStatus.None) {
          return <Navigate replace to={"../new"} />;
        } else if (isUnsupportedShielder || isDeletedShielder || hasApiKey) {
          return <Navigate replace to=".." />;
        }
        break;

      case PageType.New:
        if (project.configStatus !== ConfigStatus.None) {
          return <Navigate replace to=".." />;
        } else if (isUnsupportedShielder || isDeletedShielder) {
          return <Navigate replace to="../.." />;
        }
        break;
    }
    firstTimeOnThePageRef.current = false;
  }

  const content = (
    <Layout
      iconButtonType={
        pageType === PageType.Show ? ApplicationBarIconButtonType.Back : ApplicationBarIconButtonType.Close
      }
      iconButtonClickHandler={() => {
        addAction(AppShieldingActions.NavigateBack, AppShieldingFeatures.Configurations);
        return closePage();
      }}
      iconButtonAriaLabel={
        pageType === PageType.Show
          ? t("component.configuration-page.left-button.aria-label")
          : t("component.configuration-page.secondary-button.aria-label")
      }
      isLoading={isLoadingMetadata}
      title={t(titleTranslationKey[pageType], { project: project?.projectName ?? "" })}
      titlePostfix={
        !isLoadingMetadata &&
        !isLoadingConfiguration && <ConfigurationDebugModeTag isEditMode={pageType !== PageType.Show} />
      }
      contentBoxProps={{ flexDirection: "column", alignItems: "center" }}
      appBarContent={
        pageType === PageType.Show ? (
          <ConfigurationEditButton
            project={project}
            hasApiKey={hasApiKey}
            disabled={isLoading || isMutating || isUnsupportedShielder || isDeletedShielder}
          />
        ) : (
          <>
            {debuggable &&
              (isLoading ? <TextSkeleton width={170} height={45} /> : <DebugModeSwitch disabled={isMutating} />)}
            <ConfigurationSubmitButton
              disabled={isLoading || isMutating || isUnsupportedShielder || isDeletedShielder}
              pageType={pageType}
            />
            <ApplicationBarSecondaryButton
              disabled={isMutating}
              onClick={() => {
                addAction(AppShieldingActions.CancelEditConfiguration, AppShieldingFeatures.Configurations);
                return closePage();
              }}
              aria-label={t("component.configuration-page.secondary-button.aria-label")}
            >
              {t("word.cancel")}
            </ApplicationBarSecondaryButton>
          </>
        )
      }
    >
      <Box maxWidth={942} width="100%">
        {pageType !== PageType.Show && !isLoading && (
          <ConfigurationValidationCountAlert allItems={allItems} pageType={pageType} />
        )}
        {pageType === PageType.Edit && (
          <NewShielderItemsAlert newShielderVersion={newShielder?.shielderVersion} newItems={newItems} />
        )}
        {(isUnsupportedShielder || isDeletedShielder) && (
          <UnsupportedOrDeletedShielderVersionAlert
            project={project}
            type={AlertType.Configuration}
            deletedShielder={isDeletedShielder}
            unsupportedShielder={isUnsupportedShielder}
            selectVersionPath={`/${appShieldingRootPath}/projects/${projectId}/configuration/select-version`}
          />
        )}
        <ConfigurationFormContent
          debuggable={debuggable}
          isLoadingMetadata={isLoadingMetadata}
          isLoadingConfiguration={isLoadingConfiguration}
          isUpdating={isMutating}
          isEditMode={pageType !== PageType.Show}
          projectName={project?.projectName}
          newItems={newItems}
          newShielder={newShielder}
          validationDependencies={validationDependencies}
        />
      </Box>
    </Layout>
  );

  return isLoadingMetadata || isLoadingConfiguration ? (
    content
  ) : (
    <>
      <ConfigurationFormProvider
        isEditMode={pageType !== PageType.Show}
        replaceConfigurationAsync={replaceConfigurationAsync}
        mutationError={mutationError}
        closePage={closePage}
        configValues={adjustOldConfigValuesToNewShielder(configValues as ConfigValues)}
        featuresValues={featureValues ? featureValues(configValues as ConfigValues) : undefined}
        configUpdatedAt={configUpdatedAt}
        schema={configurationFormSchema}
        newDefaultConfigValues={newDefaultConfiguration}
        resetDebugOnlyFields={resetDebugOnlyFields}
        projectId={projectId}
        shielder={newShielder}
      >
        {content}
      </ConfigurationFormProvider>
      <Outlet />
    </>
  );
};
