import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";
import { UseFormSetValue } from "react-hook-form";

import { ConfigValues } from "../../api/types/config-values";
import { formItemValueName } from "../utils/form-item-value-name";

export function generateResetDebugOnlyFieldsFunction(items: ConfigurationItemType[]) {
  const debugOnlyItems = items.filter(item => item.debugModeOnly);

  return function (setValue: UseFormSetValue<ConfigValues>) {
    for (const item of debugOnlyItems) {
      if (item.data.defaultValue) {
        setValue(formItemValueName(item.name), item.data.defaultValue);
      }
    }
  };
}
