import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm, UseFormSetValue } from "react-hook-form";
import { z } from "zod";

import { MutationError } from "../../../../_common/api/errors/mutation-error";
import { useServerFormValidationErrors } from "../../../../_common/api/errors/use-server-form-validation-errors";
import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../_common/datadog";
import { ID } from "../../../../_common/types";
import { showToast } from "../../../../_common/ui/toast";
import { Platform } from "../shielders/types/platform";
import { Version } from "../shielders/types/version";
import type { ReplaceConfigurationMutateAsync } from "./api/replace-configuration/use-replace-configuration";
import { ConfigValues } from "./api/types/config-values";
import { ConfigurationFormSchema } from "./configuration-schema/generators/generate-configuration-form-schema";
import { FeatureValues } from "./configuration-schema/generators/generate-metadata-feature-values";
import { formItemValueName } from "./configuration-schema/utils/form-item-value-name";

const FORM_ID = "configuration_form";

interface ConfigurationFormProviderProps {
  isEditMode: boolean;
  schema: ConfigurationFormSchema;
  mutationError: MutationError<z.infer<ConfigurationFormSchema>> | null | undefined;
  children: ReactNode;
  featuresValues: FeatureValues | undefined;
  newDefaultConfigValues: ConfigValues;
  replaceConfigurationAsync: ReplaceConfigurationMutateAsync;
  closePage: () => void;
  configUpdatedAt: Date | undefined;
  configValues: ConfigValues | undefined;
  resetDebugOnlyFields?: (resetField: UseFormSetValue<ConfigValues>) => void;
  projectId: ID;
  shielder: { shielderId: ID; shielderPlatform: Platform; shielderVersion: Version };
}
export const ConfigurationFormProvider = ({
  children,
  isEditMode,
  featuresValues,
  schema,
  mutationError,
  replaceConfigurationAsync,
  closePage,
  configUpdatedAt,
  configValues,
  projectId,
  shielder: { shielderId, shielderPlatform, shielderVersion },
  newDefaultConfigValues,
  resetDebugOnlyFields,
}: ConfigurationFormProviderProps) => {
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: configValues ?? newDefaultConfigValues,
  });

  const generalServerErrorMessage = useServerFormValidationErrors(form, mutationError, formItemValueName);
  useEffect(() => {
    // We don't have general server validation errors (as well as any server validation errors) for the moment
    // but this will allow us to catch them in the future even if we forget to adapt a code to use Alert instead of toast
    if (generalServerErrorMessage) showToast("error", generalServerErrorMessage);
  }, [generalServerErrorMessage]);

  const isDebugMode = form.watch("debugMode") as boolean;
  const prevDebugModeRef = useRef(isDebugMode);

  useLayoutEffect(() => {
    if (isEditMode && !isDebugMode && prevDebugModeRef.current && resetDebugOnlyFields) {
      resetDebugOnlyFields(form.setValue);
    }
    prevDebugModeRef.current = isDebugMode;
  }, [form.setValue, isDebugMode, isEditMode, resetDebugOnlyFields]);

  useLayoutEffect(() => {
    if (!isEditMode) form.reset();
  }, [form, isEditMode]);

  const onSubmit: SubmitHandler<ConfigValues> = useCallback(
    configValues => {
      addAction(
        configUpdatedAt ? AppShieldingActions.EditConfiguration : AppShieldingActions.CreateConfiguration,
        AppShieldingFeatures.Configurations,
        {
          projectId,
          configurationFeatures: {
            [shielderPlatform.toLowerCase()]: featuresValues,
          },
        },
      );

      return replaceConfigurationAsync({
        configValues,
        ...(configUpdatedAt ? { configUpdatedAt } : {}),
        projectId,
        shielderId,
        shielderPlatform,
        shielderVersion,
      }).then(
        data => {
          form.reset({ ...newDefaultConfigValues, ...data.configValues });
          closePage();
        },
        () => {},
      );
    },
    [
      configUpdatedAt,
      projectId,
      shielderPlatform,
      featuresValues,
      replaceConfigurationAsync,
      shielderId,
      shielderVersion,
      form,
      newDefaultConfigValues,
      closePage,
    ],
  );

  return (
    <FormProvider {...form}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form id={FORM_ID} onSubmit={form.handleSubmit(onSubmit)} style={{ height: "100%" }}>
        {children}
      </form>
    </FormProvider>
  );
};
ConfigurationFormProvider.FORM_ID = FORM_ID;
